.account-card {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -200px 0 0 -180px;
    width: 360px;
    height: 400px;
    box-shadow: 0 0 100px rgba(0, 0, 0, .08);
    background-color: #FFF;
    color: rgba(0, 0, 0, .65);
    padding:24px;

    .account-title {
        font-size: 36px;
        margin-top: 8px;
        margin-bottom: 24px;
        text-align: center;
       //font-weight:normal;

        .tag{
            font-size:14px;
            color:#CCC;
            border:0;
            margin-left:8px;
            font-weight:normal;
        }
     }

    .account-form{
        margin-top:24px;

        .ant-form-item-control{
            overfloat: hidden;
        } 

        .ant-input-suffix .anticon{
            color:#CCC;
            cursor: pointer;
        }

        .ant-form-explain{
            font-size:12px
        }

        
        .account-forgot{
            float: right;
            color:#CCC;
            font-size:12px;
        }

        .account-message{
            float: left;
            color:#F04134;
            font-size:12px;
            
        }

        .ant-form-item-with-help{
            margin-bottom: 6px;
        }
    }
  
}


.account-button{
    margin-top: 40px;
    width:100%;
    height:36px;
}


.validation-card{
    margin: -146px 0 0 -180px;
    height: 292px;
}
@primary-color: #00838F;@border-radius-base: 0px;@body-background: #F0F2F5;