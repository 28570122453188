.projectForm{
    margin-top:20px
}

.ant-tooltip-inner,.ant-form-explain,.ant-form-extra{
   font-size:12px;
   line-height: 18px;
 }

.create-project-name{
margin-bottom: 34px;
}

.edit-project-name{
margin-bottom: 16px;
}

 .project-path{
margin-bottom: 34px;
}

.ant-form-item-with-help{
    margin-bottom: 16px;
}



.addon-before{
    color:rgba(0, 0, 0, 0.43)
}

.has-error .ant-input-group-addon {
    color: rgba(0, 0, 0, 0.43);
    border: 1px solid #d9d9d9;
    background-color: #eee;
}

.has-error .ant-form-extra{
  display: none;
}


/* use css to set position of modal */
.vertical-center-modal {
  text-align: center;
  white-space: nowrap;
}

.vertical-center-modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.vertical-center-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}









@primary-color: #00838F;@border-radius-base: 0px;@body-background: #F0F2F5;