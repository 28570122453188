.sider-btn{
    margin-left: 32px;
    padding-bottom:16px;
    & button{
        width:82px;
    }
}

/* .folder-btn{
    margin-left: 32px;
    margin-bottom:24px;
    & button{
        background-color: #F0F2F5;
    }
} */

.ant-menu-vertical{
    border-right:0;
}



@primary-color: #00838F;@border-radius-base: 0px;@body-background: #F0F2F5;