
.project-table{
    margin-right:-1px; /*消除白边*/
}

/* .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    font-weight:normal;
    padding:16px 24px; 
} */
div.ant-table-sticky-holder {
    background:#fafafa;
    padding: 0 16px;
}

.ant-table-body{
    padding: 0 16px;
}

.ant-dropdown-menu-item{
    padding: 7px 16px; /*antd 2.10 适配14px默认字体menu宽度*/
    .anticon{
        margin-right:8px;
    }
}

.ant-table-tbody > tr > td.ant-table-row-expand-icon-cell{
    padding:16px 0 16px 24px
}

.small-btn{
    font-size:12px;
    padding:2px 8px;
    /*background-color: #F7F7F7;*/
}



.span-space{
 margin: 0 6px;
}

.project-time{
  color:rgba(0, 0, 0, 0.43);
   white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    max-width: 160px;

}
.project-action{
    text-align: right;
    white-space:nowrap;
    overflow:hidden;
    padding: 0 !important;
    

    & div{
        padding:3px 24px;
        display:inline-block;
        visibility:hidden;
    }

    & .anticon{
        font-size:20px;
        margin-left:12px;
        display:inline-block;
        vertical-align: middle;
        height:48px;
        line-height:48px;
        width:32px;

    }
}

.ant-table-tbody > tr:hover > .project-action > div {
    visibility:visible;
}

.ant-table-tbody > tr > .ant-table-selection-column {
    visibility:hidden;
}

.ant-table-tbody > tr:hover > .ant-table-selection-column {
    visibility:visible;
}

.selected .ant-table-tbody > tr > .ant-table-selection-column {
    visibility:visible;
}


.project-name{
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
    & span{
        color:rgba(0, 0, 0, 0.95)
    }
    max-width: 160px; 
}

.batch-action{

    & .ant-btn{
        margin-right:8px
    }
}

.ant-tooltip-inner{
    white-space:nowrap;
    text-align:center;
    max-width: 320px;
}
/*.project-row{
    cursor:pointer;
}*/
.ant-table-body .ant-table-row{
    cursor:pointer;
   
}

.normal-row{
    & td.ant-table-row-expand-icon-cell{
        padding: 16px 0 !important;
            
        & .ant-table-row-expand-icon {
                display:none
            }
        }
        
}

tr.expanded-row > td {
   border-bottom: 0;
   
}

.close-row,.expanded-row ,.normal-row  {
    & td.project-name{
        padding-right:0;
    }  
}
.close-row,.expanded-row,.normal-row  {
    & td.project-time{
        padding-left:6px;
    }
}


  .project-header-name{
 padding:0 24px;
 display:inline-block;
 width:40%;
}

.project-header-time{
 padding:0 24px;
 width:20%;
 //display:inline-block;
}

.ant-table-row-expand-icon{
    width: 18px;
    height: 18px;
}

tr.ant-table-expanded-row-level-1:hover > td {
   background-color: #FFF
}

.pages {
    list-style-type:none;
    margin-bottom:0;

    & li{
        padding:8px;
    }
    & .page-content{
       color:rgba(0, 0, 0, 0.43);
       padding:0 8px 0 32px;
       display: inline-block;
       height:21px;
       line-height: 21px;
    
    }
    & .page-name{
       width:20%;
       //display: inline-block;
       color:rgba(0, 0, 0, 0.42);
       white-space: nowrap; 
       
       
    }
    & li:hover {
        background-color: #ebf6fd
    }
}

/*tr.ant-table-row-level-1 {
    td{
        border-bottom: 0px
    }
    
}*/

tr.ant-table-expanded-row > td {
  background-color: #FFF;
  padding:0px
}

/*tr.ant-table-expanded-row > td:first-child {
    border-bottom: 0px
}*/

.highlight {
    color:#f04134!important
}

.spin{
    height:54px;
    line-height:54px;
    text-align:center;

    & span{
        margin-left:16px;
        color:#CCC;
    }
}



@media only screen and (max-width: 768px){
.project-time,.project-action{
  display:none
}

}



@primary-color: #00838F;@border-radius-base: 0px;@body-background: #F0F2F5;