.navbar {
  background-color: #FFF;
  height: 64px;
}
.navbar .topnav {
  float: right;
  margin-left: 24px;
}
.navbar .topnav .help-btn {
  vertical-align: middle;
  font-size: 20px;
  margin-right: 24px;
  color: rgba(0, 0, 0, 0.43);
  cursor: pointer;
  /* &:hover{
        color:#0091EA;
      } */
}
.navbar .logo {
  float: left;
  font-size: 24px;
  width: 124px;
}
.navbar .search {
  width: 360px;
  position: absolute;
  margin-left: 32px;
  left: 14%;
  top: 0;
}
.navbar .tag {
  font-size: 12px;
  color: #CCC;
  border: 0;
}
@media only screen and (min-width: 1601px) {
  .navbar .search {
    left: 26%;
    width: 480px;
  }
}
@media only screen and (max-width: 768px) {
  .search,
  .topnav {
    display: none;
  }
  .navbar .logo {
    margin: 0 auto;
    float: none;
  }
}
