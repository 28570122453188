.account-card {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -200px 0 0 -180px;
  width: 360px;
  height: 400px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
  background-color: #FFF;
  color: rgba(0, 0, 0, 0.65);
  padding: 24px;
}
.account-card .account-title {
  font-size: 36px;
  margin-top: 8px;
  margin-bottom: 24px;
  text-align: center;
}
.account-card .account-title .tag {
  font-size: 14px;
  color: #CCC;
  border: 0;
  margin-left: 8px;
  font-weight: normal;
}
.account-card .account-form {
  margin-top: 24px;
}
.account-card .account-form .ant-form-item-control {
  overfloat: hidden;
}
.account-card .account-form .ant-input-suffix .anticon {
  color: #CCC;
  cursor: pointer;
}
.account-card .account-form .ant-form-explain {
  font-size: 12px;
}
.account-card .account-form .account-forgot {
  float: right;
  color: #CCC;
  font-size: 12px;
}
.account-card .account-form .account-message {
  float: left;
  color: #F04134;
  font-size: 12px;
}
.account-card .account-form .ant-form-item-with-help {
  margin-bottom: 6px;
}
.account-button {
  margin-top: 40px;
  width: 100%;
  height: 36px;
}
.validation-card {
  margin: -146px 0 0 -180px;
  height: 292px;
}
