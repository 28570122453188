/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
} */

@import '~antd/dist/antd.less';

.header {
  background-color: #FFF;
  height: 64px;
  margin-bottom: 24px;
  padding: 0 48px;
  box-shadow: 4px 4px 40px 0 rgba(0,0,0,.05);
}

.body{
  background-color:#F2F2F2;
}

.body{
  & .ant-layout-sider{
   margin-left:24px;
   width:200px;
   background-color:#F2F2F2;
   /* height: 100vh; */
   
   /* position: absolute;
   left:2%;
   top: 88px; */
   /* bottom: 0px;   */
 }
}

.ant-layout.ant-layout-has-sider > .project-list{
  width: auto;
}
.project-list{
  /* position: absolute;
  right:14%;
  left: 14%;  */
  /* //position:fixed; */
  position: fixed;
  /* height: 100vh; */
  /* margin-left:24px;
  margin-right:12%; */
  background-color: #FFF;
  top: 100px;
  right: 8%;
  left: 248px;
  bottom:0; 
  overflow: auto;  
}

.sider{
  top: 100px;
  position: fixed;
  bottom:0; 
  overflow: auto;
  -ms-overflow-style: none;
}

.sider::-webkit-scrollbar {
  display: none;
}


@primary-color: #00838F;@border-radius-base: 0px;@body-background: #F0F2F5;