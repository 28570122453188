div.folders-tree{
    background: #F0F2F5;

    & .ant-tree-title{
        display: inline-flex;
        justify-content: space-between;
         
        .anticon{
            line-height: 24px;
        }

        .folder-name{
           width: 116px;
           text-overflow: ellipsis;
           white-space:nowrap;
           overflow: hidden;
        }

        .leaf-folder-name{
            width: 92px;
            text-overflow: ellipsis;
            white-space:nowrap;
            overflow: hidden;
         }
    }

    & .ant-tree-treenode{
        padding: 0px 0 16px 0;

        & .ant-tree-node-content-wrapper.ant-tree-node-selected{
            color: #00838F !important; 
        }
    }
    & .ant-tree-treenode{
        background: #F0F2F5;
        width: 196px;
        
        &::before{
            background: #F0F2F5 !important;     
        }
    }

    & .folder-more {
        display: none;
    }
    & .ant-tree-treenode-selected .folder-more {
        display: inline-block;
    }
}

.target-folder{
    height: 360px;
    overflow-y: auto;

    .ant-tree{
        padding: 24px 0;
    }
}


/* .folder-more{
    float: right;
} */


@primary-color: #00838F;@border-radius-base: 0px;@body-background: #F0F2F5;