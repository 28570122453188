div.folders-tree {
  background: #F0F2F5;
}
div.folders-tree .ant-tree-title {
  display: inline-flex;
  justify-content: space-between;
}
div.folders-tree .ant-tree-title .anticon {
  line-height: 24px;
}
div.folders-tree .ant-tree-title .folder-name {
  width: 116px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
div.folders-tree .ant-tree-title .leaf-folder-name {
  width: 92px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
div.folders-tree .ant-tree-treenode {
  padding: 0px 0 16px 0;
}
div.folders-tree .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #00838F !important;
}
div.folders-tree .ant-tree-treenode {
  background: #F0F2F5;
  width: 196px;
}
div.folders-tree .ant-tree-treenode::before {
  background: #F0F2F5 !important;
}
div.folders-tree .folder-more {
  display: none;
}
div.folders-tree .ant-tree-treenode-selected .folder-more {
  display: inline-block;
}
.target-folder {
  height: 360px;
  overflow-y: auto;
}
.target-folder .ant-tree {
  padding: 24px 0;
}
/* .folder-more{
    float: right;
} */
